@font-face{
  font-family: "NunitoSans";
  src: url("./NunitoSans-Regular.ttf");
}

*{
  padding: 0 0 0 0 ;
  margin:  0 0 0  0;
  font-size: 3vh;
  color: #dddddd;
  font-family: NunitoSans
}
body{
  background-color: #444444;
  width: 100vw;
  height: 100vh;
}
/* Wide screen */
@media (min-width: 100vh){
  body{
    overflow: hidden;
  }
}


.App{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

canvas{
  image-rendering:crisp-edges;

  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;

  /* image-rendering: optimizeSpeed;
  image-rendering: crisp-edges;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor; */
}

div.top_bar{
  width:100vw;
  /* height: 35vh; */
  padding-top: 1vh;
}
div.top_bar .top_bar_row{
  padding-top: 1vh;
  padding-bottom: 1vh;
  width: 100vw;
  /* background-color: orange; */
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
}
div.top_left_corner{
  min-width: 15vw;
  height: 20vh;
  rotate: -5deg;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: green; */
}
/* video ad */
div.top_left_corner iframe{
  /* min-width: 15vw; */
  --height: 15vh;
  height: var(--height);
  width: min(calc(var(--height)*1.6108), 15vw);
}
div.top_left_corner iframe:hover{
  /* min-width: 15vw; */
  --height: 30vh;
  height: var(--height);
  width: min(calc(var(--height)*1.6108), 15vw);
}
/* image ad */
div.top_left_corner img{
  --height: 15vh;
  height: var(--height);
  width: auto;
}
div.top_left_corner:hover{
  animation: wobble 2s infinite;
  cursor: pointer;
}

div.top_right_corner{
  min-width: 15vw;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items:  center;
  rotate: 3deg;
  /* background-color: blue; */
}
div.top_right_corner img{
  width: 10vw;
  height: auto;
  /* rotate: -3deg; */
  padding-right: 2vw
}
div.top_right_corner img:hover{
  animation: wobble 2s infinite;
  cursor: pointer;
}
/* Tall screen */
@media (max-width: 100vh){
  div.top_left_corner{
    width: 100vw;
    order: 0;
  }
  div.top_right_corner{
    width: 100vw;
  }
  div.top_right_corner img{
    width: auto;
    height: 10vh;
    padding-right: 0
  }
  div.top_right_corner .byline{
    display: none;
  }
}

div.top_center{
  width: 70vw;
  height: 20vh;
  /* background-color: red; */
  display:flex;
  justify-content: center;
}
canvas#logo_canvas{
  --width: 20vh;
  height: var(--width);
  width: calc(var(--width) / 0.175);
}
/* Tall screen */
@media (max-width: 100vh){
  canvas#logo_canvas{
    --width: 95vw;
    width: var(--width);
    height: calc(var(--width) * 0.175);
  }
  div.top_center{
    width: 100vw;
    height: 10vh;
  }
}



.MapEditorComponent canvas{
  width: 10vw;
  height: 60vh;
}
.MapEditorComponent
  .dialogue_container
    .PaletteDisplayCanvas,
.MapEditorComponent
  .dialogue_container
    .PaletteDisplayCanvas
      canvas{
  width: 100vw;
  height: auto;
  animation: none;
}
.MapEditorComponent .palette_dialogue{
  display: flex;
  justify-content: center;
  align-items: center;
  width:100vw;
  /* height: 100vh; */
  /* background-color: red; */
}
.MapEditorComponent .palette_dialogue{
  /* width:min(33vw,33vh);
  height:min(33vw,33vh);
  border-radius: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center; */
}


div.palette_browser{
  width: 20.5vw;
  height: 70vh;
  /* background-color: green; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
div.palette_list{
  width:80%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: scroll;
  border: .1vh solid #dddddd;
  border-radius: 1vh;
  padding: 1vh;
}
div.palette_list header{
  max-width: 16vw;
  overflow: hidden;
  font-size: 2vh;
}
.palette_list .PaletteDisplayCanvas{
  width: 100%;
}
.palette_list .PaletteDisplayCanvas canvas{
  /* width:5vh; */
  width: 100%;
  max-width: 16vw;
  height: 5vh;
}
div.drag_note{
  /* font-size: 5vh; */
  font-style: italic;
}

div.dialogue_container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.dialogue_container.dark{
  background-color: #000000CC
}



.top_bar .PaletteDisplayCanvas{
  height: 10vh;
  width: 100%;
}
.PaletteDisplayCanvas canvas{
  height: auto;
  width: 100%;
  max-width: 100vw;
}
.palette_editor_container{
  height: 10vh;
  width: 100vw;
  background-color: red;
}
.palette_editor_container.big{
  height: 20vh;
}
canvas#active_palette_canvas{
  height: 10vh;
  width: 100%;
  animation: none;
  /* animation: beckon 2s infinite; */
}
.palette_editor_container.big
  canvas#active_palette_canvas{
  height: 6.66vh;
}



canvas#source_canvas, canvas#destination_canvas{
  /* height: 33vw; */
  width: 30vw;
  height: auto;
  /* width: auto; */
}
/* Wide screen */
@media (min-width: 100vh){
  canvas#source_canvas, canvas#destination_canvas{
    max-height: 70vh;
  }
}



.App {
  text-align: center;
}

@keyframes wobble {
   0% { transform: rotate(0deg); }
   25% { transform: rotate(.5deg); }
   75% { transform: rotate(-.5deg); }
  100% { transform: rotate(0deg); }
}
.wobbling{
  animation: wobble .25s infinite;
}

@keyframes beckon {
    0% { transform: rotate(0deg); }
   10% { transform: rotate(0deg); }
   40% { transform: rotate(1deg); }
   70% { transform: rotate(-1deg); }
  100% { transform: rotate(0deg); }
}
canvas:hover{
  animation: wobble 2s infinite;
  cursor: pointer;
}
canvas#logo_canvas{
  cursor: default;
}
canvas#map_editor_canvas:hover{
  animation: none;
}


div.dialogue{
  width:min(33vw,33vh);
  height:min(33vw,33vh);
  border-radius: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

button{
  width: 80%;
  color: #dddddd;
  background-color: #00000000;
  border-radius: 5vh;
  border: 1px solid #dddddd;
  padding: 1vh 1vh 1vh 1vh;
  font-size: 2vh;
  min-width: 10vw;
}
.ColorSlider{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
}

.ColorSlider .slider{
  width: 80%;
}
.ColorSlider .textbox{
  font-size: 3vh;
  width: 6vh;
  margin-right: 1vh;
  background-color: #00000000;
  color:#dddddd;
  border: none;
  text-align: center;
}

.magic_slider_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:25vh;
}
.magic_slider_container input{
  width: 100%;
  height: auto;
  padding: auto auto auto auto;
}
.litte_note{
  font-size: 1vh;
}
